<template>
  <div class="wameed-dashboard-page-content">
    <section class="wameed-dashboard-page-content_header" v-if="getAllJobs">
      <div
        class="
          align-items-center
          justify-content-between
          flex-wrap
          mb-2
          px-5
          pb-1
          d-none d-flex
        "
      >
        <div class="col-md-5 col-sm-12 px-0 mt-3">
          <text-input
            prepenIcon="search-icon"
            id="login-email"
            v-model="search"
            v-debounce:1000ms="jobSearch"
            inputClasses="text-book-16"
            name="search"
            :placeholder="$t('search') + '...'"
            fieldClasses="mb-0 search-form-control"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div class="mx-2 mt-3">
            <wameed-btn
              classes=" text-medium-16  rounded-14 text-white"
              :title="$t('adding_new_job')"
              type="button"
              variant="main"
              @submitAction="addJob()"
            />
          </div>
          <div class="ml-2 mt-3">
            <wameed-btn
              classes="text-medium-16    rounded-14 "
              :title="$t('skills_and_requirements')"
              type="button"
              variant="outline-main"
              @submitAction="applicationDetails()"
            />
          </div>
        </div>
      </div>
    </section>
    <section v-if="getAllJobs" class="wameed-dashboard-page-content_body">
      <b-row class="no-gutters px-2">
        <b-col
          lg="6"
          md="6"
          class="px-0 py-3"
          v-for="(item, i) in getAllJobs"
          :key="i"
        >
          <b-card
            no-body
            class="cursor-pointer wameed-card h-100"
            @click="jobDetails(item.id)"
          >
            <b-card-body
              class="
                position-relative
                d-flex
                flex-nowrap
                justify-content-between
              "
            >
              <b-media no-body class="flex-wrap">
                <b-media-aside>
                  <b-avatar variant="gray" rounded size="88">
                    <span class="text-bold-18 text-font-secondary">
                      {{ item.image }}</span
                    >
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-medium-20 text-font-main mb-3 mx-2">
                    {{ item.name }}
                  </h5>
                  <div
                    class="mb-0 text-medium-14 text-font-secondary mb-3 mx-2"
                  >
                    <clock-icon />
                    <span v-if="item.type == 1" class="mx-2">{{
                        $t("full_time") 
                    }}</span>
                     <span v-if="item.type == 2" class="mx-2">{{
                      $t("part_time") 
                    }}</span>
                     <span v-if="item.type == 3" class="mx-2">{{
                      $t("contract") 
                    }}</span>
                     <span v-if="item.type == 4" class="mx-2">{{
                      $t("freelancer") 
                    }}</span>
                    <jobs-bag-icon />
                    <span class="mx-2"> {{ item.section.title }}</span>
                  </div>
                  <div
                    v-if="item.place.name != 'Remotely'"
                    class="mb-0 text-medium-14 text-font-secondary mx-2"
                  >
                    <location-icon />

                    <span class="mx-2"> {{ item.place.name }}</span>
                  </div>
                  <div v-if="item.place.name == 'Remotely'" class="d-flex">
                    <b-badge
                      variant="light-main"
                      pill
                      class="text-medium-11 px-4"
                    >
                      {{ item.place.name }}
                    </b-badge>
                  </div>
                </b-media-body>
              </b-media>
              <span class="mx-2 text-medium-14 text-font-secondary job-date">{{
                formatedDate(item.created_at)
              }}</span>
              <!-- <div class=" d-flex flex-1 flex-column  justify-content-between   "> -->
              <b-badge
                v-if="moment().diff(moment(item.created_at), 'days') < 7"
                variant="secondary2"
                class="text-medium-11 px-4 job-new"
              >
                {{ $t("new_job") }}
              </b-badge>

              <!-- </div> -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <section
      v-if="getAllJobs && getAllJobs.length < 1"
      class="
        wameed-dashboard-page-content_body
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <b-avatar class="my-4 mx-auto" variant="white" size="115">
          <nodata-icon />
        </b-avatar>
        <h5 class="mb-0 text-bold-22 text-font-main my-2 mx-2">
          {{ $t("no_data") }}
        </h5>
        <h5 class="mb-0 text-book-20 text-font-sub my-2 mx-2">
          {{ $t("no_data_subtitle_jobs") }}
        </h5>
      </div>
    </section>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import WameedBtn from "@/components/WameedBtn.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TextInput,
    WameedBtn,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      getAllJobs: "getAllJobs",
      getAllExperience: "getAllExperience",
      getAllPlaces: "getAllPlaces",
      getAllSections: "getAllSections",
    }),
  },
  created() {
    this.loadJobs(this.search);
    this.loadExperience();
    this.loadPlaces();
    this.loadSections();
  },

  methods: {
    ...mapActions({
      loadJobs: "loadJobs",
      loadExperience: "loadExperience",
      loadPlaces: "loadPlaces",
      loadSections: "loadSections",
    }),
    jobSearch() {
      this.loadJobs(this.search);
    },
    formatedDate(date) {
      return this.moment().diff(this.moment(date), "days") > 7
        ? this.moment(date).format("YYYY-MM-DD")
        : this.moment(date).locale(this.$i18n.locale).fromNow(true);
    },
    jobDetails(id) {
      this.$router.push({
        name: "job-details",
        params: { lang: this.$i18n.locale, id: id },
      });
    },
    addJob() {
      this.$router.push({
        name: "add-job",
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>
 
